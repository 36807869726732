import styled from 'styled-components'
import { breakpoint } from '../../../Common/Helpers/breakpoint'
import theme from '../../../theme'

const { breakpoints: { md } } = theme

const LoginStyled = styled.main`
  min-height: 100vh;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-end;
  
  @media (${ breakpoint(md) }) {
    justify-content: unset;
    flex-direction: row;
  }

  .content-container {
    background-color: white;
    z-index: 2;
    height: 50%;
    min-height: 480px;
    width: 100%;
    
    @media (${ breakpoint(md) }) {
      min-width: 500px;
      width: 40%;
    }
  }
  
  .form-container {
    z-index: 1;
    background-color: white;
    margin: 0 auto;
    width: 100%;
    padding: 0 16px;
    max-width: 375px;
    height: 100%;
    
    @media (${ breakpoint(md) }) {
      max-width: unset;
      width: 350px;
    }
  }
  
  .form-logo {
    display: block;
    width: 132px;
    margin: 32px auto 40px;
    
    @media (${ breakpoint(md) }) {
      width: 175px;
      margin: 0 auto 40px;
    }
  }

  .form {
    margin: 0 auto;
  }

  .background-container {
    height: 50%;
    width: 100%;
    max-height: 180px;
    
    @media (${ breakpoint(md) }) {
      max-height: unset;
      position: absolute;
      top: 0;
    }
  }

  .diagonal-lines {
    display: none;
    
    @media (${ breakpoint(md) }) {
      display: block;
      width: 40%;
      height: 100%;
      min-width: 500px;
      background-color: white;
      position: fixed;
      z-index: 1;
  
      &::before {
        top: -10px;
        right: -120px;
      }

      &::after {
        top: -10px;
        right: -240px;
        opacity: 0.5;
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        background-color: white;
        width: 240px;
        height: 110%;
        -webkit-transform: rotate(-8deg);
        transform: rotate(-8deg);
        z-index: 0;
      }
    }
  }

  .background-image {
    height: 100%;
    object-fit: cover;
    object-position: center;
    width: 100%;
    z-index: 0;
    
    @media (${ breakpoint(md) }) {
      position: fixed;
      right: 0;
      width: 60%;
    }
  }

  .phone-icon {
    height: 100%;
    font-size: 21px;
    color: ${ theme.colors.primary };
  }

  .phone-helpdesk {
    display: flex;
    gap: 15px;
    
    @media (${ breakpoint(md) }) {
      margin-top: 3rem;
    }
  }
  
  .helpdesk-contactNo { 
    display: grid;
    grid-template-columns: auto;
    text-align: start;
    padding-top: 5px;
  }

  .knowledge-centre-link {
    color: ${theme.colors.black} ;
    text-decoration: underline;
    &:hover {
      color: ${ theme.colors.primary };
    }
  }

  .phone-text {
    color: ${ theme.colors.black };
    text-decoration: none;
    font-size: 13px;
  }

  .warning-message-card {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    z-index: 3;

    @media (${ breakpoint(md) }) {
      top: 0;
      left: 0;
      margin: 12px 24px;
      width: 40%;
      min-width: 500px;
    }
  }
`

export default LoginStyled
