import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Auth } from '@aws-amplify/auth'
import { Input, Button, Heading, textVariants, Helper } from '@reams/components'
import { Form, Radio } from 'antd'
import theme from '../../../theme'
import mfaRegisterSms from '../component.register.sms'
import LoginFormStyled from './LoginFormStyled'

function MfaRegisterMethod() {
  const [form] = Form.useForm()

  const { state = {} } = useLocation()

  const navigate = useNavigate()

  const [selectedMfaMethod, setSelectedMfaMethod] = useState(state?.mfa || null)
  const [registerDisabled, setRegisterDisabled] = useState(true)

  useEffect(() => {
    if (selectedMfaMethod) {
      setRegisterDisabled(false)
    }
  }, [selectedMfaMethod])

  console.log('win auth', window.auth)

  const user = window.auth.user
  const userPhoneNumber =
    user.signInUserSession.idToken.payload?.phone_number || ''
  console.log('username', user.username)

  async function handleOnRegisterMfa() {
    navigate('/mfaRegisterToken')
    return
    // TODO: reinstate when SMS TEXT message sending is resolved
    console.log('selectedMfaMethod', selectedMfaMethod)
    if (selectedMfaMethod === 'sms') {
      form.validateFields()
      const phoneNumber = form.getFieldValue('phone')

      console.log('regSubmit', phoneNumber)
      console.log('username', user.username)

      // TODO: set preferred MFA
      // const x = await Auth.setPreferredMFA(user, 'SMS_MFA')
      // console.log('setPreferredMFA', x)

      mfaRegisterSms(phoneNumber).then(() => {
        console.log('mfaRegisterSms called')
        navigate('/mfaChallenge')
      })
    }

    if (selectedMfaMethod === 'app') {
      navigate('/mfaRegisterToken')
      return
    }

    if (selectedMfaMethod === 'email') {
      // not yet implemented
      return
    }
  }

  return (
    <LoginFormStyled>
      <Heading
        className="header-spacing"
        color={theme.colors.primary}
        variant={textVariants.H2}
      >
        Register for MFA
      </Heading>
      <p>
        An account you have access to requires additional authentication using
        Multi Factor Authentication (MFA).
      </p>
      <p>
        Please see the{' '}
        <a
          className="external-links"
          href="https://help.eliasam.com/mfa-elias/"
          target="_blank"
        >
          ELIAS MFA help page
        </a>{' '}
        for more information.
      </p>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        // onFinish={() => handleOnSubmit()}
        onFinishFailed={() => {}}
      >
        {/* <Form.Item
          rules={[
            {
              required: true,
              message: 'Please input your phone number',
            },
          ]}
          className="form-input"
          initialValue={userPhoneNumber}
          label="Phone Number"
          name="phone"
        >
          <Input autoComplete="off" id="phone" size="default" type="phone" />
        </Form.Item> */}

        <Form.Item>
          <div className="content-right-wrapper">
            <Button
              // disabled={registerDisabled}
              type="primary"
              onClick={e => {
                e.preventDefault()

                handleOnRegisterMfa()
              }}
            >
              Register
            </Button>
          </div>
        </Form.Item>
      </Form>
    </LoginFormStyled>
  )
}

/*
  <Form.Item>
          <Radio.Group
            className="authentication-radio-group"
            value={selectedMfaMethod}
            onChange={e => setSelectedMfaMethod(e.target.value)}
          >
            <div className="inline-radio-helper">
              <Radio value="sms">Use SMS for MFA</Radio>
              <Helper tooltipTitle="Select SMS as your preferred MFA authentication method. You will receive your MFA verification codes on your phone." />
            </div>
            <div className="inline-radio-helper">
              <Radio value="app">Use Authentication App for MFA</Radio>
              <Helper tooltipTitle="Select Authentication App as your preferred Authentication method. You will need either the Google or Microsoft Authentication App on your phone to set up and get MFA verification codes." />
            </div>
          </Radio.Group>
        </Form.Item>

*/

export default MfaRegisterMethod
