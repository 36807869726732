import styled from 'styled-components'

const CodeRequestButtonStyled = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  padding: 4px 0;

  svg {
    display: flex;
    flex-shrink: 0;
    height: 20px;
    width: 20px;
    margin-right: 4px;
  }

  .label {
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;

    svg {
      color: #ff1945;
      transition: color 0.2s;
    }

    .label {
      color: #ff1945;
      transition: color 0.2s;
    }
  }
`

export default CodeRequestButtonStyled
