/* eslint-disable no-console, no-unused-vars */
import { Auth } from '@aws-amplify/auth'
import {
  MessageCard, Text, ContactHelpButton
} from '@reams/components'
import React, {
  useState, useEffect
} from 'react'
import { getSession } from '../../Common/Utils/Auth/AuthCookies'
import PinkLogo from './assets/images/Elias_Pink.png'
import LoginBackground from './assets/images/login-bg-hd.jpg'
import LoginStyled from './Pages/LoginStyled'
import LoginWrapperStyled from './Pages/LoginWrapperStyled'
import spinner from './spinner.gif'

const LoginLayout = ({
  children, appLoading
}) => {
  const [ session, setSession ] = useState()
  const [ sessionLoading, setSessionLoading ] = useState(true)

  async function setInitialUserSession(){
    setSessionLoading(true)
    await getSession({ Auth }).then((s) => {
      setSession(s)
    })
    setSessionLoading(false)
  }


  useEffect(() => {
    setInitialUserSession()
  }, [])

  if (sessionLoading) return null

  return (
    <LoginWrapperStyled>
      <LoginStyled>
        <div className="content-container">
          <div className="form-container">
            <img
              alt="logo"
              className="form-logo"
              src={PinkLogo} />

            {window.location.search.indexOf('inactive=true') > -1 && (
              <MessageCard
                className="warning-message-card"
                closable
                description="Please login again to gain access to ELIAS"
                message="You have been logged out due to inactivity"
                showIcon
              />
            )}

            <div className="form">
              {!appLoading && children}
            </div>

            {appLoading && (<>
              <img
                style={{
                  display: 'block',
                  margin: '0 auto'
                }}
                alt="loading..."
                src={spinner}
                width={100} />

              <Text style={{ textAlign: 'center' }}>
                Please wait...
              </Text>
            </>
            )}
          </div>
        </div>

        <div className="background-container">
          <div className="diagonal-lines" />

          <img
            alt="background"
            className="background-image"
            src={LoginBackground}
          />
        </div>
      </LoginStyled>

      <div className="help-container">

        <ContactHelpButton
          modalWidth={360}
        />
      </div>
    </LoginWrapperStyled>
  )
}

export default LoginLayout
