/*
  development code - to be deleted

*/

import { Auth } from '@aws-amplify/auth'

function Stop() {
  console.log('auth', window.auth)
  const email = window.auth?.session.idToken.payload.email || ''
  const tenantId = window.auth?.session.idToken.payload.tenantId || ''
  const mfaRequired = window.auth?.session.idToken.payload.mfaRequired || ''
  const preferredMfa = window.auth?.caUser.preferredMFA || ''

  console.log('email', email)

  function clear() {
    window.auth = {}
    Auth.signOut().then(() => {
      window.location.href = '/'
    })
  }

  return (
    <div>
      <h1>Stoped</h1>
      <div>
        <div>User : {email}</div>
        <div>Tenant: {tenantId}</div>
        <div>MFA Required: {mfaRequired}</div>
        <div>Prefered MFA: {preferredMfa}</div>
        <br />
      </div>
      <div>
        <a href="/">Login</a>
      </div>
      <div>
        <a href="/mfaChallenge">MFA Challenge</a>
      </div>
      <div>
        <a href="/mfaRegisterToken">MFA Register Token</a>
      </div>
      <div>
        <a href="/mfaChallengePreference">MFA Challenge preferrences</a>
      </div>
      <div>
        <button onClick={clear}>Clear</button>
      </div>

      <div>{window.auth.user}</div>
    </div>
  )
}

export default Stop
