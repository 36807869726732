import React from 'react'
import SyncIcon from './SyncIcon'
import CodeRequestButtonStyled from './CodeRequestButtonStyled'

const CodeRequestButton = ({
  label = "Get new code",
  onClick = () => {}
}) => (
  <CodeRequestButtonStyled
    onClick={() => onClick()}
  >
    <SyncIcon />
    <span className="label">{label}</span>
  </CodeRequestButtonStyled>
)

export default CodeRequestButton
