import { Auth } from '@aws-amplify/auth'
import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { getSession } from '../../Common/Utils/Auth/AuthCookies'
import CONFIG from '../../config.json'
import LoginLayout from './LoginLayout'
import ForgotPassword from './Pages/ForgotPassword'
import LoginForm from './Pages/LoginForm'
import ResetPassword from './Pages/ResetPassword'
import redirect from './component.redirect'
import MfaChallenge from './Pages/MfaChallenge'
import MfaRegisterToken from './Pages/MfaRegisterToken'
import MfaRegisterMethod from './Pages/MfaRegisterMethod'
import Stop from './Pages/Stop'
import Develop from './Pages/Develop'
import MfaChallengePreference from './Pages/MfaChallengePreference'

const LoginRouter = () => {
  const [cognitoUser, setCognitoUser] = React.useState()
  const [appLoading, setAppLoading] = React.useState()

  async function onLogin() {
    setAppLoading(true)
    const success = await redirect()
    if (!success) {
      setAppLoading(false)
    }
  }

  return (
    <LoginLayout appLoading={appLoading}>
      <Routes>
        <Route element={<ForgotPassword />} path="forgot" />

        <Route
          element={
            <ResetPassword
              cognitoUser={cognitoUser}
              setCognitoUser={setCognitoUser}
              onLogin={onLogin}
            />
          }
          path="reset"
        />

        <Route element={<MfaRegisterMethod />} path="mfaRegisterMethod" />

        <Route element={<MfaRegisterToken />} path="mfaRegisterToken" />

        <Route element={<MfaChallenge />} path="mfaChallenge" />

        <Route
          element={<MfaChallengePreference />}
          path="mfaChallengePreference"
        />

        <Route element={<Stop />} path="stop" />
        <Route element={<Develop />} path="develop" />

        <Route
          element={
            <LoginForm setCognitoUser={setCognitoUser} onLogin={onLogin} />
          }
          path="*"
        />
      </Routes>
    </LoginLayout>
  )
}

export default LoginRouter
