import React, { useState } from 'react'
import { Auth } from '@aws-amplify/auth'
import { Button, Heading, textVariants } from '@reams/components'
import { Form } from 'antd'
import CodeRequestButton from '../components/CodeRequestButton'
import CodeInputBox from '../components/CodeInputBox'
import { MFA_VERIFICATION_CODE_LENGTH } from '../../../config'
import redirect from '../component.redirect'
import theme from '../../../theme'
import LoginFormStyled from './LoginFormStyled'

/**
 * Takes MFA (6 number) challenge code from token Application, SMS text or email
 * Performs Auth.confirmSignIn() to confirm the sign-in
 * Redirects the user on success
 */

// TODO: Make input automagically submit when 6 digits are entered
function MfaChallenge() {
  const APP_AUTH_ERROR =
    'Please use the code provided by your authentication app.'
  const SMS_AUTH_ERROR = 'Please use the code received via SMS from your phone.'

  const [form] = Form.useForm()

  const [showCodeError, setShowCodeError] = useState(false)

  console.log('win auth', window.auth)

  const user = window.auth.user
  const preferredMFA = user?.preferredMFA || ''
  const challengeName = user?.challengeName || ''

  function handleConfirm() {
    form.validateFields()

    form.submit()
  }

  function handleOnSubmit(values) {
    console.log('submit', values)
    setShowCodeError(false)

    const oneTimeUserCodeFromDevice = form.getFieldValue('code')

    if (window.auth.mfaSmsCode) {
      if (window.auth.mfaSmsCode + '' === oneTimeUserCodeFromDevice + '') {
        redirect(user)
        return
      }
    }

    console.log('otc', oneTimeUserCodeFromDevice, challengeName)

    // Attempt verification and show correct error message if not correct code
    Auth.confirmSignIn(user, oneTimeUserCodeFromDevice, challengeName)
      .then(data => {
        window.auth.user = data
        console.log('confirmSignIn', data)
        redirect(user)
      })
      .catch(e => {
        // Invalid code? or user waited too long?
        console.error(e)

        setShowCodeError(true)
      })
  }

  return (
    <LoginFormStyled>
      <Heading
        className="header-spacing"
        color={theme.colors.primary}
        variant={textVariants.H2}
      >
        MFA Challenge
      </Heading>

      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={values => handleOnSubmit(values)}
        onFinishFailed={() => {}}
      >
        <div className="mfa-codebox-wrapper">
          <CodeInputBox
            requiredCodeLength={MFA_VERIFICATION_CODE_LENGTH}
            label={`Verification Code (${MFA_VERIFICATION_CODE_LENGTH} characters)`}
            hideExternalValidation={() => setShowCodeError(false)}
          />
          {showCodeError && (
            <div className="auth-error-validation">
              {preferredMFA === 'sms' ? SMS_AUTH_ERROR : APP_AUTH_ERROR}
            </div>
          )}
        </div>
        <Form.Item className="action-group">
          <div
            className={
              preferredMFA === 'sms' ? 'actions' : 'content-right-wrapper'
            }
          >
            {preferredMFA === 'sms' && (
              <CodeRequestButton
                text="Resend code"
                onClick={() => {
                  console.log('Request new code')
                }}
              />
            )}
            <Button
              type="primary"
              onClick={e => {
                e.preventDefault()

                handleConfirm()
              }}
            >
              Confirm
            </Button>
          </div>
        </Form.Item>
      </Form>
    </LoginFormStyled>
  )
}

export default MfaChallenge
