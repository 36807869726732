/*
  development code - to be deleted

*/

import { fromWebToken } from '@aws-sdk/credential-providers'

import { DynamoDBClient } from '@aws-sdk/client-dynamodb'
import {
  DynamoDBDocumentClient,
  ScanCommand,
  QueryCommand,
  UpdateCommand,
  UpdateItemCommand,
} from '@aws-sdk/lib-dynamodb'

import {
  CognitoIdentityProviderClient,
  VerifySoftwareTokenCommand,
  AdminSetUserMFAPreferenceCommand,
  SetUserMFAPreferenceCommand,
  AdminUpdateUserAttributesCommand,
  AdminInitiateAuthCommand,
  InitiateAuthCommand,
} from '@aws-sdk/client-cognito-identity-provider'

import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity'
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity'

import { SSMClient, GetParameterCommand } from '@aws-sdk/client-ssm'

import { SNSClient, PublishCommand } from '@aws-sdk/client-sns'
import CONFIG from '../../config.json'

// import { AssumeRoleCommand, STSClient } from '@aws-sdk/client-sts'

// const STS = require("aws-sdk/clients/sts")

// import STS from 'aws-sdk/clients/sts'

async function develop() {
  console.log('develop')

  const identityPoolId = CONFIG.cognito.identityPoolId
  const region = 'eu-west-2'

  // Initialize the Cognito Identity Client
  const client = new CognitoIdentityClient({ region })

  // Obtain credentials
  const credentials = fromCognitoIdentityPool({
    client,
    identityPoolId,
  })

  const creds = await credentials()

  const ciPclient = new CognitoIdentityProviderClient({
    region: 'eu-west-2',
    credentials: creds,
  })
  const cipInp = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: '1qkd138setpi803vi8ef132gfj',
    AuthParameters: {
      USERNAME: 'testmfasms@test.com',
      PASSWORD: 'passW0rd!',
    },
  }

  const cpir = await ciPclient.send(new InitiateAuthCommand(cipInp))

  console.log('cipr', cpir)

  return

  // Use the credentials to make authenticated requests
  // credentials().then(creds => {
  //   console.log('Authenticated with AWS', creds)
  //   // You can now use these credentials to make requests to other AWS services
  // })

  console.log('credentials', creds)
  process.env.STAGE = 'dev1'

  const smmClient = new SSMClient({
    region: 'eu-west-2',
    credentials: creds,
  })

  try {
    const getEnv = await smmClient.send(
      new GetParameterCommand({
        Name: `/dev1/opensearch/endpoint`,
      })
      // new GetParameterCommand({
      //   Name: `/${process.env.STAGE}/opensearch/endpoint`,
      // })
    )
    console.log('getEnv', getEnv)
  } catch (e) {
    console.log('error', e)
  }

  // const x = fromNodeProviderChain({ clientCongfig: { region: 'eu-west-2' } })
  // console.log('x', x)

  // const stsc = new STSClient({ region: 'eu-west-2' })
  // const stsc = new STS({ region: 'eu-west-2' })

  // const command = {
  //   // The Amazon Resource Name (ARN) of the role to assume.
  //   RoleArn: 'arn:aws:iam::860639527556:role/dev1-loginRole',
  //   // An identifier for the assumed role session.
  //   RoleSessionName: 'session1a',
  //   // The duration, in seconds, of the role session. The value specified
  //   // can range from 900 seconds (15 minutes) up to the maximum session
  //   // duration set for the role.
  //   DurationSeconds: 900,
  // }

  // const command = new AssumeRoleCommand({
  //   // The Amazon Resource Name (ARN) of the role to assume.
  //   RoleArn: 'arn:aws:iam::860639527556:role/dev1-loginRole',
  //   // An identifier for the assumed role session.
  //   RoleSessionName: 'session1a',
  //   // The duration, in seconds, of the role session. The value specified
  //   // can range from 900 seconds (15 minutes) up to the maximum session
  //   // duration set for the role.
  //   DurationSeconds: 900,
  // })

  // try {
  //   // const r = await stsc.send(command)
  //   const r = await STS.assumeRole(command).promise()

  //   console.log('r', r)
  // } catch (e) {
  //   console.log('error', e)
  // }

  const ddb = new DynamoDBClient({
    region: 'eu-west-2',
    credentials: creds,
  })
  const ddbDoc = DynamoDBDocumentClient.from(ddb)
  const scan = new ScanCommand({ TableName: 'dev1-users' })
  try {
    const result = await ddbDoc.send(scan)
    console.log('result', result)
  } catch (e) {
    console.log('error', e)
  }

  const sns = new SNSClient({
    region: 'eu-west-2',
    credentials: creds,
  })

  const input = {
    PhoneNumber: '+447472403015',
    Message: 'Your verification code is 123456',
  }
  const cmd = new PublishCommand(input)
  try {
    const response = await sns.send(cmd)
    console.log('mfaRegisterSms', response)
    return response
  } catch (e) {
    console.log('mfaRegisterSms', e)
  }
}

export default develop
