async function getSession({ Auth }){
  try {
    return await Auth.currentSession()
  } catch(e) {
    console.warn(e)

    return null
  }
}

export { getSession }
