import styled from 'styled-components'
import { breakpoint } from '../../../Common/Helpers/breakpoint'
import theme from '../../../theme'

const { breakpoints: { md } } = theme

const LoginFormStyled = styled.div`
  padding-bottom: 50px;
 
  & .ant-form-vertical .ant-form-item-label {
      padding: 0 0 2px;
  }

  .form-link {
    font-size: 16px;
    color: ${ theme.colors.additional1 };
    text-decoration: underline;
    padding-top: 16px;

    @media (${ breakpoint(md) }) {
      font-size: 14px;
      padding-top: 0;
    }
  }

  .form-input {
    padding-bottom: ${ theme.spacing.desktop.g }px;
    
    @media (${ breakpoint(md) }) {
      padding-bottom: 4px;
    }
  }
  
  .action-group {
    margin-top: 16px;
    width: 100%;
  }

  .actions {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column-reverse;

    .ant-btn {
      width: 100%;
    }

    @media (${ breakpoint(md) }) {
      flex-direction: row;

      .ant-btn {
        width: unset;
      }
    }
  }

  .authentication-radio-group {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .inline-radio-helper {
    display: inline-flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .content-right-wrapper {
    display: flex;
    width: 100%;
    justify-content: right;
  }

  .external-links {
    color: ${theme.colors.secondary};
    text-decoration: underline;

    &:hover {
      color: ${theme.colors.primary};
      transition: color 0.2s;
      cursor: pointer;
    }
  }

  .ant-radio-checked {
    .ant-radio-inner {
      border-color: ${theme.colors.primary};
    }

    .ant-radio-inner::after {
      background-color: ${theme.colors.primary};
    }
  }

  .auth-error-validation {
    margin-top: -28px;
    color: ${theme.colors.primary};
  }

  .qr-panel {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

    .qr-code {
      display: block;
      width: 175px;
      height: 175px;
      margin: 0 auto;
    }

    .qr-text {
      display: inline;
      inline-size: 100%;
      overflow-wrap: break-word;
      white-space: normal;
      margin-bottom: 16px;
      text-align: center;
      font-size: 12px;
      color: ${theme.colors.additional1};
    }
  }

  .mfa-codebox-wrapper {
    margin-bottom: 40px;
  }
`

export default LoginFormStyled
