const baseColors = {
  grey2: "#f1f4f3",
  grey3: "#d9d9d9",
  red: "#ff1945",
  blue: "#4b6ad1",
  lightBlue: "#62aee6",
  green: "#21ad37",
  lightGreen: "#89d837",
  purple: "#b431c6",
  lightPurple: "db3eb1",
  orange: "#ff7757",
  salmon: "#fd9070",
  darkYellow: "#fac560",
  yellow: "#ffe834",
}

const customColors = { tableGreyOpaque: "#fbfbfb" }

const theme = {
  colors: {
    white: "#ffffff",
    black: "#000000",
    textLight: "#b4b8bc",
    primary: baseColors.red,
    secondary: "#232323",
    positive: baseColors.green,
    negative: baseColors.red,
    warning: baseColors.orange,
    info: baseColors.lightBlue,
    borderDark: "#1A1818",
    borderDarken: "#707070",
    border: "#CBCBCB",
    hover: "#0190fe",
    borderLighten: baseColors.grey3,
    additional1: "#444f56",
    additional2: baseColors.grey2,
    additional3: baseColors.grey3,
    additional1Trans2: "rgba(68, 79, 86, 0.02)",
    additional1Trans5: "rgba(68, 79, 86, 0.05)",
    additional1Trans10: "rgba(68, 79, 86, 0.1)",
    additional1Trans20: "rgba(68, 79, 86, 0.2)",
    additional1Trans40: "rgba(68, 79, 86, 0.4)",
    chartGreen: baseColors.green,
    chartLightGreen: baseColors.lightGreen,
    chartLightBlue: baseColors.lightBlue,
    chartBlue: baseColors.blue,
    chartPurple: baseColors.purple,
    chartLightPurple: baseColors.lightPurple,
    chartOrange: baseColors.orange,
    chartLightOrange: baseColors.salmon,
    chartDarkYellow: baseColors.darkYellow,
    chartYellow: baseColors.yellow,
    custom: { ...customColors },
  },
  breakpoints: {
    //360 min
    xs: "480px",
    sm: "576px",
    md: "768px",
    lg: "992px",
    xl: "1200px",
    xxl: "1600px",
  },
  spacing: {
    desktop: { g: 8 },
    mobile: { g: 6 },
  },
  appSwitcher: {
    desktop: {
      iconHeight: 24,
      iconWidth: 24,
    },
    mobile: {},
  },
  appSwitcherMenu: {
    desktop: {
      headingFontSize: 20,
      headingFontWeight: 400,
      headingLineHeight: 1.5,
      headingPadding: "12px",
      menuItemBodyFontSize: 13,
      menuItemBodyFontWeight: 400,
      menuItemBodyIconWidth: 24,
      menuItemsPadding: "8px 12px",
      menuItemsLineHeight: 1.6,
    },
    mobile: {},
  },
  contextPanel: {
    desktop: {
      buttonHeight: 24,
      buttonWidth: 24,
      buttonWrapperHeight: 40,
      buttonWrapperWidth: 40,
      contentPadding: "0 48px 0 0",
      contentCollapsedWidth: 24,
      collapsedWidth: 75,
      expandedWidth: 275,
      margin: "0 16px 0 0",
      minWidth: 75,
    },
    mobile: { expandedWidth: "100%" },
  },
  navigationBar: {
    desktop: {
      height: 64,
      titleWidth: 200,
    },
    mobile: {
      height: 64,
      titleWidth: 200,
    },
  },
  navigationBarTitle: { width: 200 },
  navBar: {
    height: 64, // 8 x g
    titleWidth: 100,
  },
}

export default theme
