function MfaChallengePreference() {
  const user = window.auth.user
  console.log('user', user)
  const preferredMFA = ''

  function choosen(value) {
    return () => {
      console.log('choosen', value)
      if (value === 'sms') {
        console.log('sms')
      }
    }
  }
  return (
    <div>
      <h1>MFA Challenge Preference</h1>
      <div>preferred MFA method: {preferredMFA}</div>
      <button onClick={choosen('sms')}>Choose SMS</button>
      <div onClick={choosen('app')}>Choose APP</div>
      <div>Choose EMAIL</div>
    </div>
  )
}

export default MfaChallengePreference
