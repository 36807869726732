/* eslint-disable max-len */
import { Auth, CookieStorage } from '@aws-amplify/auth'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { getSession } from './Common/Utils/Auth/AuthCookies'
import CONFIG from './config.json'
import reportWebVitals from './reportWebVitals'
import './index.css'
import Cookie from 'js-cookie'
import redirect from './Routers/LoginRouter/component.redirect'

const hostname = window.location.hostname
const { userPoolId, userPoolWebClientId, identityPoolId } = CONFIG.cognito

if (
  hostname.indexOf('local.') > -1 ||
  hostname.indexOf('luc.') > -1 ||
  hostname.indexOf('localhost') > -1
) {
  Auth.configure({
    region: 'eu-west-2',
    userPoolId,
    userPoolWebClientId,
    identityPoolId,
  })
} else {
  Auth.configure({
    region: 'eu-west-2',
    userPoolId,
    userPoolWebClientId,
    identityPoolId,
    cookieStorage: new CookieStorage({
      domain: getSharedHostname(),
      sameSite: 'lax',
    }),
  })
}

run()
async function run() {
  window.auth = {}
  console.log('href', window.location.href)

  removeOldCookies()
  const queryParams = new URLSearchParams(window.location.search)
  const signout = queryParams.get('signout')
  const session = await getSession({ Auth })

  try {
    // currentAuthenticatedUser provides signInUserSession, authFlowType [SRP] and preferredMFA
    const x = await Auth.currentAuthenticatedUser()
    console.log('currentAuthenticatedUser', x)
    window.auth.caUser = x
    // window.auth.user.orig = 'index-currentAuthenticatedUser'
  } catch (e) {
    console.log('currentAuthenticatedUser error', e)
  }

  if (session && signout) {
    // console.info('Login app - signing out')
    await Auth.signOut()
  }

  if (session) {
    window.auth.session = session
    window.auth.orig = 'index-session'

    // login()
    if (window.location.href.indexOf('/stop') === -1) {
      console.info('Login app - logging in with existing session', window.auth)
      redirect(session)
    }
  }

  const root = ReactDOM.createRoot(document.getElementById('root'))

  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals()
}

function getSharedHostname() {
  const hostname = window.location.hostname
  if (hostname === 'localhost') {
    return hostname
  }
  return hostname.substring(hostname.indexOf('.'))
}

/**
 * Delete authentication cookies that may remain in browser even after update.
 * These cookies will not be set or consumed after the Nov 2023 updates.
 * Cookies can persist for up to 400 days. This code can eventually be removed.
 */
function removeOldCookies() {
  const cookieNames = [
    'elias-login-email',
    'elias-login-password',
    'login-email',
    'login-password',
  ]

  cookieNames.forEach(name => Cookie.remove(name))
}
