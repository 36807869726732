import getUserQuery from '../../Common/GraphQL/Queries/getUserQuery'
import { ROLES } from '../../config'
import CONFIG from '../../config.json'

class User {
  userRoles = []
  adminModuleAccess = false

  constructor(user){
    this.userRoles = user.roles
    this.adminModuleAccess = user.adminModuleAccess
  }

  hasRole(role){
    return this.userRoles.includes(role)
  }

  hasAnyRole(roles = []){
    let roleMatch = false

    roles.forEach(role => {
      if (this.hasRole(role)) {
        roleMatch = true
      }
    })

    return roleMatch
  }

  hasMobileLite(){
    return this.hasAnyRole([ ROLES.MOBILE_LITE, ROLES.ADMIN ])
  }

  // governance
  hasAdmin(){
    return this.hasAnyRole([
      ROLES.ADMIN, ROLES.APPROVER, ROLES.DATA_IMPORT
    ])
    || (this.hasRole(ROLES.SUPPLIER) && this.adminModuleAccess)
  }

  hasInspections(){
    return this.hasAnyRole([
      ROLES.SUPPLIER, ROLES.SURVEYOR, ROLES.FABRIC, ROLES.ADMIN
    ])
  }

  hasAnalytics(){
    return this.hasAnyRole([
      ROLES.SUPPLIER, ROLES.CUSTOMER, ROLES.ADMIN
    ])
  }

  hasOldAdmin(){
    return this.hasAnyRole([
      ROLES.SUPPLIER, ROLES.APPROVER, ROLES.ADMIN, ROLES.DATA_IMPORT
    ])
    || (this.hasRole(ROLES.SUPPLIER) && this.adminModuleAccess)
  }
}

async function getUserData({
  variables, authToken
}){
  const host = CONFIG.appsyncEndpoint.host

  const fetchData = await fetch(`https://${ host }/graphql`, {
    method: 'POST',
    headers: {
      Authorization: authToken,
      host,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      variables,
      query: getUserQuery,
      operationName: 'getUser'
    })
  })

  const fetchJson = await fetchData.json()

  return fetchJson
}


export {
  getUserData, User
}

