import {
  CognitoIdentityProviderClient,
  VerifySoftwareTokenCommand,
  AdminSetUserMFAPreferenceCommand,
  SetUserMFAPreferenceCommand,
  AdminUpdateUserAttributesCommand,
} from '@aws-sdk/client-cognito-identity-provider'

async function mfaRegisterToken(otcode) {
  console.log('mfaRegister', otcode)
  console.log('window.auth', window.auth)
  const jwtToken = window.auth.user.signInUserSession.accessToken.jwtToken
  const x = await verifyOneTimeCode(otcode, jwtToken)
  
  if (x === 'SUCCESS') {
    setTokenAsPreference(jwtToken)

    return 'SUCCESS'
  }

  return 'FAILURE'
}

async function verifyOneTimeCode(otcode, jwt) {
  const cipc = new CognitoIdentityProviderClient({ region: 'eu-west-2' })
  const inputs = {
    AccessToken: jwt,
    UserCode: otcode,
  }
  console.log('verifyOneTimeCode', otcode)
  const vstc = new VerifySoftwareTokenCommand(inputs)
  try {
    const r = await cipc.send(vstc)
    console.log('verifyOneTimeCode', r)
    return r.Status
  } catch (e) {
    console.log('verifyOneTimeCode', e)
  }
}

async function setTokenAsPreference(jwt) {
  const cipc = new CognitoIdentityProviderClient({ region: 'eu-west-2' })
  // User does not have delivery config set to turn on SMS_MFA - means no phone number

  const input = {
    // AdminSetUserMFAPreferenceRequest
    SMSMfaSettings: {
      // SMSMfaSettingsType
      Enabled: false,
      PreferredMfa: false,
    },
    SoftwareTokenMfaSettings: {
      // SoftwareTokenMfaSettingsType
      Enabled: true,
      PreferredMfa: true,
    },
    // Username: values.username, // required
    // UserPoolId: values.poolId, // required
    AccessToken: jwt,
  }
  let response = null

  // console.log(input)

  // TODO: implement Auth method of setting mfa prefs
  // import { updateMFAPreference } from 'aws-amplify/auth'
  // await updateMFAPreference({ sms: 'PREFERRED' })

  const cmd = new SetUserMFAPreferenceCommand(input)
  try {
    response = await cipc.send(cmd)
  } catch (err) {
    console.error(err)
  }

  console.log(response)
  return response
}

export default mfaRegisterToken
