import { Auth } from "@aws-amplify/auth"
import {
  Input, Button, Heading, textVariants,
} from "@reams/components"
import {
  Input as AntInput,
  Form, notification,
} from "antd"
import React, { useMemo } from "react"
import {
  Link, useSearchParams, useNavigate,
} from "react-router-dom"
import theme from "../../../theme"
import LoginFormStyled from "./LoginFormStyled"


const ForgotPassword = () => {
  const [ searchParams ] = useSearchParams()
  const navigate = useNavigate()

  const emailQueryParam = useMemo(
    () => {
      if (!searchParams.get("email")) return undefined

      return decodeURIComponent(searchParams.get("email"))
    }, [ searchParams.get("email") ],
  )


  const tryToRestore = async (values) => {
    let authError

    try {
      await Auth.forgotPassword(values.email)

      notification.success({
        message: "Reset Password",
        description: "Please check your emails for a reset code.",
      })
      navigate(`?email=${ encodeURIComponent(values.email) }`)

      return
    }
    catch (error) {
      authError = error
      notification.warning({
        message: "Sending Code Failed",
        description: authError.message,
      })
    }
  }


  function handleSendResetEmail(values){
    const {
      code, confirmPassword: password,
    } = values

    Auth.forgotPasswordSubmit(
      emailQueryParam, code, password,
    )
      .then(() => {
        notification.success({
          message: "Reset Password",
          description: "Your password has been reset.",
        })
        navigate("/login")
      })
      .catch((err) => {
        const { message } = err

        notification.warning({
          message: "Resetting Password Failed",
          description: message,
        })
      })
  }

  function renderGenerateCodeForm(){
    return (<Form
      layout="vertical"
      requiredMark={false}
      onFinish={tryToRestore}>
      <Form.Item
        rules={[ {
          required: true,
          message: "Please input your e-mail address",
          type: "email",
        } ]}
        className="form-input"
        hasFeedback
        label="Email"
        name="email"
      >
        <Input />
      </Form.Item>
      <div className="actions">
        <Link
          className="form-link"
          to="/login"
        >
      Back to Login
        </Link>
        <Button
          htmlType="submit"
          type="primary"
          // loading={authInProgress}
        >
      Confirm
        </Button>
      </div>
    </Form>)
  }

  function renderResetPasswordCodeForm(){
    return (<Form
      layout="vertical"
      requiredMark={false}
      onFinish={handleSendResetEmail}>
      <Form.Item
        rules={[ {
          required: true,
          message: "Please input your code",
        } ]}
        className="form-input"
        hasFeedback
        label="Code"
        name="code"
      >
        <Input />
      </Form.Item>
      <Form.Item
        rules={[ {
          required: true,
          message: "Please input your new password",
        } ]}
        hasFeedback
        label="New Password"
        name="newPassword"
      >
        <Input variant={AntInput.Password} />
      </Form.Item>
      <Form.Item
        rules={[ {
          required: true,
          message: "Please confirm your password",
        },
        ({ getFieldValue }) => ({ validator(
          _, value,
        ){
          if (!value || getFieldValue("newPassword") === value) {
            return Promise.resolve()
          }

          return Promise.reject(new Error("The two passwords that you entered do not match!"))
        } }) ]}
        dependencies={[ "newPassword" ]}
        hasFeedback
        label="Confirm Password"
        name="confirmPassword"
      >
        <Input variant={AntInput.Password} />
      </Form.Item>
      <div className="actions">
        <Link
          className="form-link"
          to="/login"
        >
      Back to Login
        </Link>
        <Button
          htmlType="submit"
          type="primary"
          // loading={authInProgress}
        >
      Confirm
        </Button>
      </div>
    </Form>)
  }

  return (
    <LoginFormStyled>
      <Heading
        className="header-spacing"
        color={theme.colors.primary}
        variant={textVariants.H2}
      >
      Forgot Password
      </Heading>
      {emailQueryParam ? renderResetPasswordCodeForm() : renderGenerateCodeForm()}

    </LoginFormStyled>
  )
}

export default ForgotPassword
