const getUserQuery = `
  query getUser($email: String!) { 
    getUser(email: $email) {
      email
      roles
      adminModuleAccess
    }
  }`

export default getUserQuery
