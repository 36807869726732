import React, { useState, useEffect, useRef } from 'react'
import { Form } from 'antd'
import CodeInputBoxStyled from './CodeInputBoxStyled'

const CodeInputBox = ({
  requiredCodeLength = 6,
  label = 'Input Verification Code',
  hideExternalValidation = () => {},
}) => {
  const [value, setValue] = useState('')
  const [hasBeenModified, setHasBeenModified] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef) {
      inputRef?.current?.focus()
    }
  }, [])

  useEffect(() => {
    if (hasBeenModified) {
      hideExternalValidation()
    }
  }, [value])

  return (
    <Form.Item
      rules={[
        {
          required: true,
          message: 'Invalid MFA Verification Code.',
        },
        {
          pattern: new RegExp('^.{' + requiredCodeLength + '}$'),
          message: 'Invalid MFA Verification Code.',
        },
      ]}
      className="form-input"
      label={label}
      name="code"
    >
      <CodeInputBoxStyled
        autoComplete="off"
        id="code"
        ref={inputRef}
        value={value}
        onChange={e => {
          if (!hasBeenModified) {
            setHasBeenModified(true)
          }

          setValue(e.target.value)
        }}
      />
    </Form.Item>
  )
}

export default CodeInputBox
