import { Auth } from '@aws-amplify/auth'
import { getSession } from '../../Common/Utils/Auth/AuthCookies'
import CONFIG from '../../config.json'

import { getUserData, User } from './user'

// TODO: Create tests for this module

async function redirect(session) {
  let authSession = null
  const envUrl = CONFIG.environmentUrl

  // TODO: debug/local only
  const href = window.location.href
  console.log('href', href)
  if (href.indexOf('local.') > -1) {
    window.location.replace('/stop')
    return
  }

  try {
    authSession = await Auth.currentSession()
  } catch (err) {
    console.error('login error', err)
    await Auth.signOut()

    return false
  }

  // console.log('login - authSession', authSession)

  const email = authSession.getIdToken().payload.email
  const mfaRequired = authSession.getIdToken().payload.mfaRequired
  const authToken = authSession.getAccessToken().getJwtToken()

  console.log('login - authSession xxxx', authSession) // comes after Auth.signIn - carries pre token info
  console.log('mfaRequired', mfaRequired)
  console.log('email', email)
  console.log('authToken', authToken)

  const gqlUserFetch = await getUserData({
    variables: { email },
    authToken,
  })

  console.log('gqlUserFetch', gqlUserFetch)

  const user = new User(gqlUserFetch.data.getUser)

  console.info('User roles: ', user.userRoles)
  console.info('Admin access: ', user.adminModuleAccess)

  const queryParams = new URLSearchParams(window.location.search)
  const referer = queryParams.get('referer')
  let urlRedirect

  console.log('logged in ', referer)
  // return
  if (referer) {
    const [allowed, error] = validateRedirect(user, referer)

    if (allowed) {
      urlRedirect = envUrl[referer]

      console.info('Login app - redirect from referer: ', referer)
    } else if (error) {
      console.error(error)
      await Auth.signOut()

      alert(error)

      return false
    }
  } else {
    urlRedirect = selectUrlRedirect(user.userRoles, user.adminModuleAccess)
  }

  if (urlRedirect) {
    await redirectToURLWithCredentials({ targetURL: urlRedirect })
  } else {
    console.error('URL redirect is not defined')
    await Auth.signOut()
    alert('A log in error occurred: redirect URL is not configured.')

    return false
  }
  // } catch (err) {
  //   console.error('login error', err)
  //   await Auth.signOut()

  //   return false
  // }
}

function validateRedirect(user, referer) {
  const accessDeniedError = `Access Denied: you do not have permission to access this page.`

  switch (referer) {
    case 'mobileLite':
      if (user.hasMobileLite()) {
        return [true]
      }

      return [null, accessDeniedError]

    case 'admin':
      if (user.hasAdmin()) {
        return [true]
      }

      return [null, accessDeniedError]

    case 'inspections':
      if (user.hasInspections()) {
        return [true]
      }

      return [null, accessDeniedError]

    case 'analytics':
      if (user.hasAnalytics()) {
        return [true]
      }

      return [null, accessDeniedError]

    case 'oldAdmin':
      if (user.hasOldAdmin()) {
        return [true]
      }

      return [null, accessDeniedError]

    default:
      return [null, 'Invalid URL Error']
  } // end switch
}

function selectUrlRedirect(roles, adminModuleAccess) {
  const envUrl = CONFIG.environmentUrl

  if (
    (roles.includes('supplier') && adminModuleAccess) ||
    roles.includes('admin')
  ) {
    return envUrl['oldAdmin']
  }

  if (
    roles.includes('customer') ||
    roles.includes('supplier') ||
    roles.includes('pm') ||
    roles.includes('lead')
  ) {
    return envUrl['analytics']
  }

  if (roles.includes('surveyor') || roles.includes('fabric')) {
    return envUrl['inspections']
  }

  if (roles.includes('mobileLite')) {
    return envUrl['mobileLite']
  }

  if (roles.includes('approver')) {
    return envUrl['admin']
  }

  if (roles.includes('dataImport')) {
    return envUrl['dataImport']
  }

  return envUrl['analytics']
}

async function redirectToURLWithCredentials({ targetURL }) {
  const session = await getSession({ Auth })

  if (session && targetURL) {
    const dataURL = btoa(
      JSON.stringify({
        idToken: session.idToken.jwtToken,
        userIdAuth: session.idToken.payload.sub,
        refreshToken: session.refreshToken.token,
        tenantId: 'blank',
      })
    )

    console.info('Login app - redirect to: ', targetURL)

    window.location.href = `${targetURL}?data=${dataURL}`
  }
}

export default redirect

export { redirectToURLWithCredentials, selectUrlRedirect, validateRedirect }
