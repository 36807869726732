import styled from 'styled-components'
import { breakpoint } from '../../../Common/Helpers/breakpoint'
import theme from '../../../theme'

const { breakpoints: { md } } = theme

const LoginWrapperStyled = styled.div`
  position: relative;
  min-height: 100vh;

  .help-container {
    position: relative;
    display: flex;
    width: auto;
    max-width: 375px;
    margin: 0 auto;

    @media (${ breakpoint(md) }) {
      max-width: unset;
      margin: unset;
    }
  }
`

export default LoginWrapperStyled
