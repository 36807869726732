/* eslint-disable no-unused-vars*/
import "antd/dist/antd.css"
import {
  Routes, Route, useNavigate,
} from "react-router-dom"
import LoginRouter from "./Routers/LoginRouter/LoginRouter"


const App = () => (
  <Routes>
    <Route
      element={<LoginRouter />}
      path="/*" />
  </Routes>
)

export default App
