import { Input, Button, Heading, textVariants } from '@reams/components'
import { Input as AntInput, Form, notification } from 'antd'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import theme from '../../../theme'
import LoginFormStyled from './LoginFormStyled'
import processLoginForm from '../component.login'

const LoginForm = ({ onLogin }) => {
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const handleLogin = async values => {
    const loginResult = await processLoginForm(values)
    if (loginResult.action === 'redirect') {
      navigate(loginResult.redirection)
      return
    }

    if (loginResult.action === 'logon') {
      onLogin()
      return
    }

    if (loginResult.state === 'error') {
      notification.warning({
        message: 'Login failed',
        description: loginResult.message,
      })
      return
    }
  }

  let user = window.login?.session.idToken.payload.email || ''
  console.log('user', user)

  return (
    <LoginFormStyled>
      <Heading
        className="header-spacing"
        color={theme.colors.primary}
        variant={textVariants.H2}
      >
        Login
      </Heading>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={async credentials => {
          await handleLogin({
            ...credentials,
            email: credentials.email.toLowerCase(),
          })
        }}
        onFinishFailed={x => console.info(x)}
      >
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please input your e-mail address',
              type: 'email',
            },
          ]}
          className="form-input"
          label="Email"
          name="email"
        >
          <Input size="default" />
        </Form.Item>

        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please input your password',
            },
          ]}
          className="form-input"
          label="Password"
          name="password"
        >
          <Input variant={AntInput.Password} />
        </Form.Item>

        <Form.Item className="action-group">
          <div className="actions">
            <Link className="form-link" to="/forgot">
              Forgot password?
            </Link>

            <Button
              type="primary"
              onClick={e => {
                e.preventDefault()
                form.submit()
              }}
            >
              Login
            </Button>
          </div>
        </Form.Item>
      </Form>
    </LoginFormStyled>
  )
}

export default LoginForm
