import { Auth } from '@aws-amplify/auth'

const signals = {
  logon: {
    state: 'success',
    action: 'logon',
    redirection: '',
  },
  errors: {
    unknown: {
      state: 'error',
      action: 'unknown',
      redirection: '',
    },
    forgoten: {
      state: 'error',
      action: 'redirect',
      redirection: 'forgoten',
    },
    incorrect: {
      state: 'error',
      action: 'message',
      redirection: '',
      message: 'Incorrect username or password',
    },
    exceeded: {
      state: 'error',
      action: 'message',
      redirection: '',
      message: 'Password attempts exceeded',
    },
  },

  resetPassword: {
    state: 'resetPassword',
    action: 'redirect',
    redirection: 'reset',
  },
  mfaChallenge: {
    state: 'mfaChallenge',
    action: 'redirect',
    redirection: 'mfaChallenge',
  },
  mfaRegister: {
    state: 'mfaRegister',
    action: 'redirect',
    redirection: 'mfaRegisterMethod',
  },
}

async function processLoginForm(values) {
  const { email, password } = values

  if (window.auth.session) {
    // Sign out in case another user's cookies were loaded in a separate tab
    await Auth.signOut()
    window.auth = {}
  }

  try {
    window.auth.user = await Auth.signIn(email, password)
    window.auth.orig = 'login-signIn'
  } catch (e) {
    if (e.code === 'PasswordResetRequiredException') {
      return signals.errors.forgoten
    }

    if (
      `${e}`.indexOf(
        'NotAuthorizedException: Incorrect username or password.'
      ) > -1
    ) {
      return signals.errors.incorrect
    }

    if (
      `${e}`.indexOf('NotAuthorizedException: Password attempts exceeded') > -1
    ) {
      return signals.errors.exceeded
    }

    return signals.errors.unknown
  }

  const { challengeName, challengeParam, signInUserSession, preferredMFA } =
    window.auth.user

  // signInUserSession not available with SMS
  const mfaRequired = signInUserSession?.idToken.payload.mfaRequired
  const cognito_phone_number = signInUserSession?.idToken.payload.phone_number

  if (challengeName === 'NEW_PASSWORD_REQUIRED') {
    return signals.resetPassword
  }

  if (challengeName === 'SOFTWARE_TOKEN_MFA' || challengeName === 'SMS_MFA') {
    return signals.mfaChallenge
  }

  // User needs mfa setting up
  if (
    mfaRequired === 'true' &&
    challengeName === undefined &&
    preferredMFA === 'NOMFA'
  ) {
    return signals.mfaRegister
  }

  return signals.logon
}

export { signals }
export default processLoginForm
