/*
  development code - to be deleted

*/

import { Auth } from '@aws-amplify/auth'
import { Input, Button, Heading, textVariants } from '@reams/components'
import { Input as AntInput, Checkbox, Form, notification } from 'antd'
import { useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import develop from '../component.develop'

let smsChecked = false
let athChecked = false
function Develop({ cognitoUser, setCognitoUser, onLogin }) {
  console.log('Develop')

  develop().then(res => {
    console.log('develop', res)
  })

  // const navigate = useNavigate()
  // console.log('cognitoUser', cognitoUser)
  // console.log('win auth', window.auth)

  // const user = window.auth.user
  // const phone = user.signInUserSession.idToken.payload?.phone_number || ''
  // const phoneInputRef = useRef(null)
  // phoneInputRef.current.value = phone
  // const [form] = Form.useForm()

  // function submit(values) {
  //   console.log('submit', values)
  //   console.log(Form)

  //   if (values.target.id === 'sms-check') {
  //     smsChecked = !smsChecked
  //   }
  //   if (values.target.id === 'ath-check') {
  //     athChecked = !athChecked
  //     navigate('/mfaToken')
  //   }
  //   // console.log(values.target.id)
  // }

  // async function regSubmit(values) {
  //   console.log('regSubmit', values)
  //   console.log('checks sms ath', smsChecked, athChecked)
  //   console.log('cognitoUser', cognitoUser)
  // }

  // return (
  //   <div>
  //     <h1>Develop</h1>
  //     <div></div>
  //     <div>
  //       <Form
  //         form={form}
  //         onFinish={async values => {
  //           await regSubmit({
  //             ...values,
  //           })
  //         }}
  //       >
  //         <Form.Item
  //           rules={[
  //             {
  //               // required: true,
  //               message: 'Please input your phone number',
  //               type: 'phone',
  //             },
  //           ]}
  //           className="form-input"
  //           label="Phone Number"
  //           name="phone"
  //           id="phone"
  //         >
  //           <Input size="default" ref={phoneInputRef} />
  //         </Form.Item>

  //         <Button
  //           type="primary"
  //           onClick={e => {
  //             e.preventDefault()
  //             form.submit()
  //           }}
  //         >
  //           Register
  //         </Button>
  //       </Form>
  //     </div>
  //   </div>
  // )

  function clicked(value) {
    return () => {
      console.log('clicked', value)
    }
  }

  return (
    <div>
      <h1>Develop</h1>
      <div onClick={clicked('bazze')}>Click me</div>
    </div>
  )
}

export default Develop
