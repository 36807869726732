import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Heading, textVariants } from '@reams/components'
import { Form } from 'antd'
import { renderSVG } from 'uqr'
import CodeRequestButton from '../components/CodeRequestButton'
import CodeInputBox from '../components/CodeInputBox'
import { MFA_VERIFICATION_CODE_LENGTH } from '../../../config'
import theme from '../../../theme'
import mfaRegisterToken from '../component.register.token'
import redirect from '../component.redirect'
import LoginFormStyled from './LoginFormStyled'

import {
  CognitoIdentityProviderClient,
  AssociateSoftwareTokenCommand,
} from '@aws-sdk/client-cognito-identity-provider'

const SETTINGS = {
  app: {
    issuer: 'REAMS:Elias',
    ref: 'otpauth://totp/',
  },
}

/**
 * uqr (renderSVG) makes a qrCode from a secret code string
 * The secret code comes from Cognito
 */

function MfaRegisterToken() {
  const APP_AUTH_ERROR =
    'Please use the code provided by your authentication app.'

  const email = window.auth.user?.attributes.email

  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [secretCode, setSecretCode] = useState('')
  const [showError, setShowError] = useState(false)

  // This method is used here as promise.then() creates an infinate loop
  useEffect(() => {
    async function fetchTokenCode() {
      setSecretCode(await secretRegistrationCode())
    }
    fetchTokenCode()
  }, [])

  const qrGraphicData = `${SETTINGS.app.ref}${email}?secret=${secretCode}&issuer=${SETTINGS.app.issuer}`
  const qrGraphic = renderSVG(qrGraphicData)

  function handleConfirm() {
    setShowError(false)
    form.validateFields()
    form.submit()
  }

  function handleBack() {
    navigate('/mfaRegisterMethod', { state: { mfa: 'app' } })
  }

  async function handleOnSubmit(values) {
    // check the verification code and return error if not correct code?? Needs to be run to verify
    const oneTimeUserCodeFromDevice = form.getFieldValue('code')

    console.log({ oneTimeUserCodeFromDevice })

    mfaRegisterToken(oneTimeUserCodeFromDevice).then(res => {
      if (res === 'SUCCESS') {
        redirect(res)
      } else {
        setShowError(true)
      }
    })

    return
  }

  return (
    <LoginFormStyled>
      <Heading
        className="header-spacing"
        color={theme.colors.primary}
        variant={textVariants.H2}
      >
        Register for MFA
      </Heading>
      <p>
        Use the QR code to set up your authentication app, such as Google or
        Microsoft Authenticator apps that can be used on your mobile to set up
        ELIAS MFA.
      </p>
      <p>
        Please see the{' '}
        <a
          className="external-links"
          href="https://help.eliasam.com/mfa-elias/"
          target="_blank"
        >
          ELIAS MFA help page
        </a>{' '}
        for more information.
      </p>
      <div className="qr-panel">
        <div
          className="qr-code"
          dangerouslySetInnerHTML={{ __html: qrGraphic }}
        ></div>
        <div className="qr-text">QR code: {secretCode}</div>
        <CodeRequestButton
          onClick={() => {
            console.log('Request new code')
            navigate('/mfaRegisterMethod')
          }}
        />
      </div>
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        onFinish={values => handleOnSubmit(values)}
        onFinishFailed={() => {}} // TODO - Remove, or use should code be invalid?
      >
        <div className="mfa-codebox-wrapper">
          <CodeInputBox
            requiredCodeLength={MFA_VERIFICATION_CODE_LENGTH}
            label={`Verification Code from your App (${MFA_VERIFICATION_CODE_LENGTH} characters)`}
            hideExternalValidation={() => setShowError(false)}
          />
          {showError && (
            <div className="auth-error-validation">
              {APP_AUTH_ERROR}
            </div>
          )}
        </div>
        <Form.Item className="action-group">
          <div className="content-right-wrapper">
            {/* <Button
              type="secondary"
              onClick={e => {
                e.preventDefault()
                handleBack()
              }}
            >
              Back
            </Button> */}

            <Button
              type="primary"
              onClick={e => {
                e.preventDefault()
                handleConfirm()
              }}
            >
              Confirm
            </Button>
          </div>
        </Form.Item>
      </Form>
    </LoginFormStyled>
  )
}

async function secretRegistrationCode() {
  const cipc = new CognitoIdentityProviderClient({ region: 'eu-west-2' })
  const associateSoftwareToken = new AssociateSoftwareTokenCommand({
    AccessToken: window.auth.user?.signInUserSession.accessToken.jwtToken,
  })
  const r = await cipc.send(associateSoftwareToken)
  return r.SecretCode
}

export default MfaRegisterToken
